export default function () {
    var cursor = document.querySelector('.cursor-1');
    var cursorinner = document.querySelector('.cursor-2');
    var links = document.querySelectorAll('a, button');
    var swiper = document.querySelectorAll('.swiper:not(.remove-cursor)');

    document.addEventListener('mousemove', function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
    });

    document.addEventListener('mousemove', function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + 'px';
        cursorinner.style.top = y + 'px';
    });

    document.addEventListener('mousedown', function () {
        cursor.classList.add('click');
        cursorinner.classList.add('click')
    });

    document.addEventListener('mouseup', function () {
        cursor.classList.remove('click')
        cursorinner.classList.remove('click')
    });

    swiper.forEach(item => {
        item.addEventListener('mouseover', () => {
            cursor.classList.add('drag');
            cursorinner.classList.add('drag');
        });
        item.addEventListener('mouseleave', () => {
            cursor.classList.remove('drag');
            cursorinner.classList.remove('drag');
        });
    });

    links.forEach(item => {
        item.addEventListener('mouseover', () => {
            cursor.classList.add('hover');
            cursorinner.classList.add('hover');
        });
        item.addEventListener('mouseleave', () => {
            cursor.classList.remove('hover');
            cursorinner.classList.remove('hover');
        });
    });
}