export default function () {
    new Swiper(".sustainability-initiation-01-swiper", {
        slidesPerView: 1.2,
        spaceBetween: 15,
        centeredSlides: true,
        autoplay: true,
        speed: 700,
        rewind: true,

        navigation: {
            nextEl: ".sustainability-initiation-01-swiper-btn-next",
            prevEl: ".sustainability-initiation-01-swiper-btn-prev",
        },

        breakpoints: {
            768: {
                slidesPerView: 'auto',
                centeredSlides: false,
            },

            992: {
                slidesPerView: 'auto',
                spaceBetween: 30,
                centeredSlides: false,
            },

            1200: {
                slidesPerView: 'auto',
                spaceBetween: 61,
                centeredSlides: false,
            }
        }
    });
}