/**
 * wad Site
 */

/* eslint-env browser */
// 'use strict';

// import 'propper.js';
// import '@popperjs/core';
import aos from './vendor/aos';

// header
import header from './components/header';

// cursor
import cursor from './components/cursor';

// home
import homeHeroSwiper from './components/home-hero-swiper';
import homeOurStoryCertificationSwiper from './components/home-our-story-certifications-swiper';
import homeProductSwiper from './components/home-product-swiper';
import homeOurFocusSwiper from './components/home-our-focus-swiper';

// sustainability
import sustainabilityInitiation01 from './components/sustainability-initiation-01-swiper';

// cecilbio
import cecilBioProductRangeSwiper from './components/cecilbio-product-range-swiper';
import cecilBioCertificationSwiper from './components/cecilbio-certification-swiper';

// products
import productsIsotope from './components/products-isotope';

// product inner
import postProductImageSwiper from './components/post-product-image-swiper';

// our story
import ourStoryHistorySwiper from './components/our-story-history-swiper';

// contact us
import contactUsTestimonialsSwiper from './components/contact-us-testimonials-swiper';
import contactUsHeroSwiper from './components/contact-us-hero-swiper';

// tootip
import tooltips from './components/tooltips';


(function () {
    aos.init({once: true, duration: 800});

    header();
    cursor();
    tooltips();
    homeHeroSwiper();
    homeOurStoryCertificationSwiper();
    homeProductSwiper();
    homeOurFocusSwiper();
    sustainabilityInitiation01();
    cecilBioProductRangeSwiper();
    cecilBioCertificationSwiper();
    ourStoryHistorySwiper();
    postProductImageSwiper();
    contactUsTestimonialsSwiper();
    contactUsHeroSwiper();
    productsIsotope();
})();