export default function () {
    new Swiper(".cecilbio-certification-swiper", {
        slidesPerView: 1,
        autoplay: true,
        spaceBetween: 15,
        rewind: true,
        speed: 700,
        pagination: {
            el: ".cecilbio-certification-swiper-pagination",
        },
    });
}