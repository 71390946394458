export default function () {
    new Swiper(".cecilbio-product-range-swiper", {
        slidesPerView: 'auto',
        spaceBetween: 15,
        centeredSlides: true,
        autoplay: true,
        speed: 700,
        rewind: true,

        breakpoints: {
            576: {
                freeMode: true,
                centeredSlides: false,
                spaceBetween: 30
            },
        }
    });
}