export default function () {
    new Swiper(".home-our-focus-swiper", {
        slidesPerView: 1,
        spaceBetween: 15,
        autoplay: true,
        speed: 700,

        pagination: {
            el: ".home-our-focus-swiper-pagination",
        },

        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },

            1200: {
                slidesPerView: 3,
                spaceBetween: 65
            },
        }
    });
}