export default function () {
    new Swiper(".home-our-story-certification-swiper", {
        slidesPerView: 2,
        spaceBetween: 30,
        autoplay: true,
        rewind: true,
        speed: 700,

        pagination: {
            el: ".home-our-story-certification-swiper-pagination",
        },

        breakpoints: {
            768: {
                slidesPerView: 5,
            },
        }
    });
}