export default function () {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {
        html: true,
    }));

    // Show all tooltips
    // tooltipList.forEach(tooltipInstance => {
    //     tooltipInstance.show();
    // });
}