export default function () {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("header").style.top = "0";
        }
        else if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            document.getElementById("header").style.top = "-110px";
        }
        prevScrollpos = currentScrollPos;
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#header .navbar').addClass('shadow-lg');
        } else {
            $('#header .navbar').removeClass('shadow-lg');
        }
    });

    $('#menu-main-menu li.dropdown').each(function () {
        if ($(this).find('.dropdown-menu .current-menu-item').length > 0) {
            $(this).addClass('active');
        }
    });

    var navbarToggler = document.querySelector('.navbar-toggler .icon');

    navbarToggler.addEventListener('click', function () {
        navbarToggler.classList.toggle('open');
    })
}