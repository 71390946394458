export default function () {
    // Check if an element with the class "products-subcategories" exists on the page
    if ($('.products-subcategories').length === 0) {
        return;
    }

    // init Isotope
    var $grid = $('.products-subcategories .product-item-container').isotope({
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
    });

    // bind filter button click
    $('.products-subcategories .button-container').on('click', 'button', function () {
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({ filter: filterValue + ', .fixed' });
    });

    // change is-checked class on buttons
    $('.products-subcategories .button-container').each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', '.btn-outline', function () {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
        });

        $buttonGroup.on('click', '.dropdown-item', function () {
            $buttonGroup.find('.active').removeClass('active');
            $(this).addClass('active');
        });
    });


    //****************************
    // Isotope Load more button
    //****************************
    var initShow = 7; //number of items loaded on init & onclick load more button
    var counter = 3; //counter for load more button
    var iso = $grid.data('isotope'); // get Isotope instance

    loadMore(initShow); //execute function onload

    function loadMore(toShow) {
        $grid.find(".hidden").removeClass("hidden");

        var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function (item) {
            return item.element;
        });
        $(hiddenElems).addClass('hidden');
        $grid.isotope('layout');

        //when no more to load, hide show more button
        if (hiddenElems.length == 0) {
            jQuery(".load-more-products").hide();
        } else {
            jQuery(".load-more-products").show();
        };

    }
    //when load more button clicked
    $(".load-more-products").click(function () {
        if ($('.products-subcategories .button-container').data('clicked')) {
            counter = initShow;
            $('.products-subcategories .button-container').data('clicked', false);
        } else {
            counter = counter;
        };
        counter = counter + initShow;
        loadMore(counter);
    });

    //when filter button clicked
    $(".products-subcategories .button-container").click(function () {
        $(this).data('clicked', true);
        loadMore(initShow);
    });
}