export default function () {
    new Swiper(".home-product-swiper", {
        slidesPerView: 'auto',
        spaceBetween: 15,
        autoplay: true,
        centeredSlides: true,
        rewind: true,
        speed: 700,

        breakpoints: {
            576: {
                freeMode: true,
                centeredSlides: false,
                loop: false,
                spaceBetween: 32
            },
        }
    });
}