export default function () {
    new Swiper(".contact-us-testimonials-swiper", {
        slidesPerView: 1.1,
        spaceBetween: 15,
        autoplay: true,
        centeredSlides: true,
        speed: 700,
        rewind: true,

        breakpoints: {
            576: {
                slidesPerView: 'auto',
                spaceBetween: 48.5,
                centeredSlides: false,
            },
        }
    });
}