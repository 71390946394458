export default function () {
    new Swiper(".contact-us-hero-swiper", {
        slidesPerView: 1,
        autoplay: true,
        effect : 'fade',
        rewind: true,
        speed: 700,
        navigation: {
            nextEl: ".contact-us-hero-swiper-btn.next",
            prevEl: ".contact-us-hero-swiper-btn.prev",
        },
    });
}