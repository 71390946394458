export default function () {
    new Swiper(".home-hero-swiper", {
        slidesPerView: 1,
        effect : 'fade',
        autoplay: true,
        rewind: true,
        speed: 700,
        pagination: {
            el: ".home-hero-swiper-pagination",
        },
        navigation: {
            nextEl: ".home-hero-swiper-btn.next",
            prevEl: ".home-hero-swiper-btn.prev",
        },
    });
}